<!-- 挂载--Z40S fckernel -->
<template>
  <div class="cpt-MMC_Gimbal pr">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">多气体检测</div>
      </div>
      <div>
        <div class="cp pa right50 iconfont icon-moshiqiehuan cf mr0" @click="showList"></div>
        <div @click="closeFn" class="close">关闭</div>
      </div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item mb10">
          <div class="label-box">气体检测：</div>
          <div class="input-box">
            <el-switch v-model="GSAFlag" @change="switchFn" active-color="#1f4ef5" inactive-color="#dee1e6"></el-switch>
            <el-select @change="taskSituationFn" class="ml20" v-model="taskSituation" placeholder="请选择执行状态">
              <el-option label="CO(ppm)" value="CO(ppm)"></el-option>
              <el-option label="NO2(ppm)" value="NO2(ppm)"></el-option>
              <el-option label="PM1.0" value="PM1.0(ug/m3)"></el-option>
              <el-option label="PM2.5" value="PM2.5(ug/m3)"></el-option>
              <el-option label="PM10" value="PM10(ug/m3)"></el-option>
              <el-option label="Ox(ppm)" value="Ox(ppm)"></el-option>
              <el-option label="SO2(ppm)" value="SO2(ppm)"></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <CO :airData="airData['CO(ppm)']" @fn='changetaskSituation'></CO>
          <NO2 class="mt6" :airData="airData['NO2(ppm)']" @fn='changetaskSituation'></NO2>
          <PM1 class="mt6" :airData="airData['PM1.0(ug/m3)']" @fn='changetaskSituation'></PM1>
          <PM2_5 class="mt6" :airData="airData['PM2.5(ug/m3)']" @fn='changetaskSituation'></PM2_5>
          <PM10 class="mt6" :airData="airData['PM10(ug/m3)']" @fn='changetaskSituation'></PM10>
          <SO2 class="mt6" :airData="airData['SO2(ppm)']" @fn='changetaskSituation'></SO2>
          <Ox class="mt6" :airData="airData['Ox(ppm)']" @fn='changetaskSituation'></Ox>
        </div>
      </div>
      <div class="pa top40 right5">
        <div class="cf pa top-12 tc right0 color_num">
          <div>0</div>
          <div :class="item.class" v-for="(item, index) in colorlist" :key="index">
            <span v-if="taskSituation == 'NO2(ppm)'">{{ item.num1 }}</span>
            <span
              v-else-if="taskSituation == 'PM1.0(ug/m3)' || taskSituation == 'PM10(ug/m3)' || taskSituation == 'PM2.5(ug/m3)'">{{
                item.num2 }}</span>
            <span v-else>{{ item.num }}</span>
          </div>
        </div>
        <img src="./color.png" class="dib w10 h350" alt />
      </div>
    </div>
    <dialogNew :isShow="visible" :data="warningInfor">
      <div class="safetyWarningDoneY mb31" slot="footer" @click="closeDialog">确认</div>
    </dialogNew>
  </div>
</template>
  
<script>
import CO from "./CO.vue";
import NO2 from "./NO2.vue";
import PM1 from "./PM.vue";
import PM2_5 from "./PM2.vue";
import PM10 from "./PM10.vue";
import SO2 from "./SO2.vue";
import Ox from "./Ox.vue";
import API from "@/api";
import Popup from "./message/popup.js";
import message from "./message";
import data from "./data";
import mqtt from "./message/mqtt";
import { mapGetters } from "vuex";
import dialogNew from "@/components/dialogNew/index.vue";
let GSAentities = [];
let handler = null;
export default {
  props: {
    position: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ...data,
      warningInfor: {
        title: "危险提示",
        msg: '检测到气体Ox(ppm)数值为21,大于0.1ppm对人体造成伤害（短期接触限值）'
      },
      visible: false,
      GSAFlag: false,
      taskSituation: "CO(ppm)",
      GAStopic: "",
      airData: {
        "CO(ppm)": 0,
        "NO2(ppm)": 0,
        "Ox(ppm)": 0,
        "PM1.0(ug/m3)": 0,
        "PM10(ug/m3)": 0,
        "PM2.5(ug/m3)": 0,
        "SO2(ppm)": 0,
      },
      time: null,
      lineCopy: [
        {
          x: -2678764.420122798,
          y: 4607649.276733472,
          z: 3503558.1793838153,
        },
      ],
      positions: {
        x: -2678781.728398804,
        y: 4607699.369921466,
        z: 3503589.8612064472,
      },

      uavModel: null,
      flag: true,
      height: 0,
      air_detectionTypeListData: null,
      popupList: [], //弹出框实例列表
      move_id_arr: [],
      colorlist: [
        {
          class: "mt12",
          num: "50",
          num1: "5",
          num2: "20",
        },
        {
          class: "mt13",
          num: "100",
          num1: "10",
          num2: "40",
        },
        {
          class: "mt15",
          num: "150",
          num1: "15",
          num2: "60",
        },
        {
          class: "mt16",
          num: "200",
          num1: "20",
          num2: "80",
        },
        {
          class: "mt16",
          num: "250",
          num1: "25",
          num2: "100",
        },
        {
          class: "mt14",
          num: "300",
          num1: "30",
          num2: "120",
        },
        {
          class: "mt14",
          num: "350",
          num1: "35",
          num2: "140",
        },
        {
          class: "mt14",
          num: "400",
          num1: "40",
          num2: "160",
        },
        {
          class: "mt15",
          num: "450",
          num1: "45",
          num2: "180",
        },
        {
          class: "mt16",
          num: "500",
          num1: "50",
          num2: "200",
        },
      ],
    };
  },
  components: {
    CO,
    NO2,
    PM1,
    PM2_5,
    PM10,
    SO2,
    Ox,
    dialogNew
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted() {
    this.air_detectionTypeList();
    this.GAStopic = this.$store.state.device.gastopic;
   this.initMqtt();
    this.click_billboard()
  },
  beforeDestroy() {
    let viewer = window.viewer;
    this.unsubscribe(this.GAStopic);
    viewer.scene.postRender.removeEventListener(this.onPostRender);
    this.removeEntities();
    if (handler) {
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler = null;
    }
    if (this.popupList.length > 0) {
      this.popupList.forEach((item) => {
        item.close();
      });
    }

    let { client } = this.mqtt;
    if (client) {
      client.end();
      client = null;
    }
  },
  methods: {
    ...mqtt,
    ...message,
    click_billboard() {
      let viewer = window.viewer;
      const _this = this;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        let pick = new Cesium.Cartesian2(
          movement.position.x + 15,
          movement.position.y + 10
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          let data = pickedLabel.id;

          let arr = _this.move_id_arr;
          try {
            if (!data._properties._customProperty) {
              return false;
            }
            if (data._name.indexOf("entity_gas") < 0) {
              return false;
            }
            console.log(data._name, "data._name)");
            if (arr.indexOf(data._name) < 0) {
              _this.move_id_arr.push(data._name);
              // 调用弹窗方法
              let popup = new Popup({
                viewer: viewer,
                geometry: cartesian,
                data: data,
                arr: _this,
              });
              // console.log('创建弹窗', popup)
              _this.popupList.push(popup);
            }
          } catch (error) {
            // console.log("创建弹窗异常:", error);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      handler.setInputAction((movement) => {
        // console.log('滚动', _this.popupList)
        //滚动关闭所有信息框
        _this.popupList.forEach((item) => {
          item.close();
        });
        _this.popupList = [];
      }, Cesium.ScreenSpaceEventType.WHEEL);
    },
    // 切换展示的气体
    changetaskSituation(item) {
      if (this.taskSituation !== item) {
        this.taskSituation = item
        this.taskSituationFn()
      }
    },
    closeFn() {
      this.$emit("close");
    },
    // 气体类型列表
    async air_detectionTypeList() {
      let res = await API.FCKERNEL.air_detectionTypeList({
        paeNo: 1,
        pageSize: 10,
      });
      console.log(res, "res");
      if (res) {
        this.air_detectionTypeListData = res.records;
      }
    },
    showList() {
      // 打开历史记录软件
      this.$store.commit("device/SET_MMCGimbalGASList_FLAG", true);
    },
    taskSituationFn() {
      GSAentities.forEach(async (entity) => {
        if (entity.show) {
          let customData = entity.properties.customProperty;
          let num = customData._value.airData[this.taskSituation];
          try {
            if (num || num == 0) {
              entity.polygon.material = await this.color_from_value(num);
              entity.label.text = num + "";
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    async initTopic() {
      let { id } = this.device;
      let res = await API.FCKERNEL.initTopic({
        topic: this.GAStopic,
        deviceId: id,
      });
      console.log(res);
    },
    switchFn() {
      if (this.GSAFlag) {
        this.initTopic();
        this.initRectangle();

      } else {
        let data = [];
        let getArea = null;
        let length = null;
        GSAentities.forEach((entity) => {
          if (entity.show) {
            let polygon = entity.polygon;
            //  数据
            let customData = entity.properties.customProperty;
            if (polygon) {
              let hierarchy = polygon.hierarchy.getValue(
                Cesium.JulianDate.now()
              );
              let hierarchypositions = hierarchy.positions;
              // 使用 JulianDate 获取 entity 位置
              const time = Cesium.JulianDate.fromDate(new Date());
              const position = entity.position.getValue(time);
              data.push({
                positions: hierarchypositions,
                data: customData._value,
                position: position,
              });
              //  面积(平方米)
              getArea = this.getArea(hierarchypositions);
              length = Math.sqrt(getArea).toFixed(2);
            }
          }
        });
        let str = length + "米 x" + length + "米(" + getArea + "平方米)";
        this.updateMqttTopicFn(str, data, getArea * data.length);
        window.viewer.scene.postRender.removeEventListener(this.onPostRender);
        // this.removeEntities();
      }
    },
    async updateMqttTopicFn(str, data, getArea) {
      let { id } = this.device;

      let res = await API.FCKERNEL.updateMqttTopic({
        topic: this.GAStopic,
        deviceId: id,
        averageSize: str,
        area: getArea,
        areaInfo: JSON.stringify(data),
      });

      this.$confirm("是否需要下载当前报告").then((action) => {
        let type = this.air_detectionTypeListData.filter(
          (res) => res.typeDesc == this.taskSituation
        );
        this.screenshot(type[0].id);
      }).catch()
    },
    async screenshot(typeId) {
      function dataURItoBlob(dataURI) {
        let byteString = atob(dataURI.split(",")[1]);
        let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      }
      let canvas = window.viewer.scene.canvas;
      let image = canvas.toDataURL("image/png");
      let blob = dataURItoBlob(image);
      let formData = new FormData();
      // 接口
      formData.append("uploadFiles", blob, `下载.jpeg`);
      let res = await API.MAPMANAGE.fileUpload(formData);
      if (res.length > 0) {
        API.FCKERNEL.reportExport({
          imageUrl: res[0].storage || null,
          deviceId: this.device.id,
          fileType: 0,
          typeId: typeId,
        }).then((res) => {
          const wordBlob = new Blob([res], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          saveAs(wordBlob, "气体检测报告.docx");
        });
      } else {
        this.$message.error(res?.message);
      }
    },
    initRectangle() {
      let viewer = window.viewer;
      if (GSAentities.length == 0) {
        let data_source = viewer.dataSources.getByName("FCKERNEL_LAYER")[0];
        let entities = data_source.entities;
        let { deviceHardId } = this.device;
        this.uavModel = entities.getById(`fckernel_model_${deviceHardId}`);
        if (this.uavModel) {
          let time = Cesium.JulianDate.now();
          let position = this.uavModel.position.getValue(time);
          let newpositions = this.Cartesian3_to_WGS84_Arr(position);
          // let newpositions = this.Cartesian3_to_WGS84_Arr(this.positions);
          // 创建正方形区域
          this.createRectangle(viewer, newpositions);

        }
      }
      setTimeout(() => {
        viewer.scene.postRender.addEventListener(this.onPostRender);
      }, 2000);
    },

    createRectangle(viewer, newpositions) {
      let t = 50; // 小方块的数量，t*t等于总方块数
      let step = 0.0006; // 小方块的大小，单位是度
      for (let i = 0; i < t; i++) {
        for (let j = 0; j < t; j++) {
          let num = i * (t / 2) + j;
          let entity = viewer.entities.add({
            name: `entity_gas_${i}_${j}`,
            position: Cesium.Cartesian3.fromDegrees(
              newpositions[0] + (i - t / 2) * step,
              newpositions[1] + (j - t / 2) * step,
              this.height
            ),
            polygon: {
              hierarchy: Cesium.Cartesian3.fromDegreesArray([
                newpositions[0] + (i - t / 2) * step - step / 2,
                newpositions[1] + (j - t / 2) * step - step / 2,
                newpositions[0] + (i - t / 2) * step + step / 2,
                newpositions[1] + (j - t / 2) * step - step / 2,
                newpositions[0] + (i - t / 2) * step + step / 2,
                newpositions[1] + (j - t / 2) * step + step / 2,
                newpositions[0] + (i - t / 2) * step - step / 2,
                newpositions[1] + (j - t / 2) * step + step / 2,
              ]),
              material: this.color_from_value(0), // 设置颜色和透明度
              height: 0, // 设置高度
              // outline: true,
              // outlineColor: Cesium.Color.BLACK,
              outlineWidth: 1.0,
            },
            label: {
              text: "0", // 设置显示的文字
              font: "20px Microsoft YaHei",
              fillColor: Cesium.Color.fromCssColorString("#282c34"),
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              showBackground: false,
              scaleByDistance: new Cesium.NearFarScalar(800, 2.0, 900, 0.6),
              outlineWidth: 0,
              scale: 0.8,
              // pixelOffset: new Cesium.Cartesian2(0, -10),
            },
            properties: {
              customProperty: {
                airData: {
                  "CO(ppm)": 0.5828,
                  "NO2(ppm)": 0.0174,
                  "Ox(ppm)": 0.0748,
                  "PM1.0(ug/m3)": 1,
                  "PM10(ug/m3)": 2,
                  "PM2.5(ug/m3)": 1,
                  "SO2(ppm)": 0,
                },
                altitude: 30.4036,
                hdop: 2.41,
                humidity: 55.5766,
                latitude: 33.32563947,
                longitude: 120.28557247,
                pressure: 100963.1687,
                sateNum: 5,
                sequence: 4604,
                serial: "29653f00",
                temperature: 26.6259,
                utcTime: "2023-07-17-09-25-38",
              },
            },
            show: false,
          });
          GSAentities.push(entity);
        }
      }
    },
    onPostRender(clock) {
      if (this.flag) {
        this.flag = false;
        let time = Cesium.JulianDate.now();
        let position = this.uavModel.position.getValue(time);
        // let position = this.positions;
        if (position) {
          this.isCartesianInPolygons(position);
        }
      }
    },
    isCartesianInPolygons(position) {
      try {
        for (let i = 0; i < GSAentities.length; i++) {
          const entity = GSAentities[i];
          let polygon = entity.polygon;
          if (polygon) {
            let hierarchy = polygon.hierarchy.getValue(Cesium.JulianDate.now());
            let hierarchypositions = hierarchy.positions;
            let cartographic = Cesium.Cartographic.fromCartesian(position);
            let rectangle =
              Cesium.Rectangle.fromCartesianArray(hierarchypositions);
            let isContained = Cesium.Rectangle.contains(
              rectangle,
              cartographic
            );
            if (isContained) {
              entity.show = true;
              let num = this.airData[this.taskSituation];
              if (num) {
                entity.label.text = num + "";
                entity.properties.customProperty = this.customProperty;
                entity.polygon.material = this.color_from_value(num);
                setTimeout(() => {
                  this.flag = true;
                }, 2500);
                return true; // 位置在多边形内
              }
            }
          }
        }
        setTimeout(() => {
          this.flag = true;
        }, 1500);
        console.log("位置不在任何多边形内");
        return false; // 位置不在任何多边形内
      } catch (error) {
        setTimeout(() => {
          this.flag = true;
        }, 1500);
        console.log("出现错误，位置不在任何多边形内", error);
        return false; // 出现错误，位置不在任何多边形内
      }
    },
    /* 测量空间面积 */
    // 方向
    Bearing(from, to) {
      let fromCartographic = Cesium.Cartographic.fromCartesian(from);
      let toCartographic = Cesium.Cartographic.fromCartesian(to);
      let lat1 = fromCartographic.latitude;
      let lon1 = fromCartographic.longitude;
      let lat2 = toCartographic.latitude;
      let lon2 = toCartographic.longitude;
      let angle = -Math.atan2(
        Math.sin(lon1 - lon2) * Math.cos(lat2),
        Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
      );
      if (angle < 0) {
        angle += Math.PI * 2.0;
      }
      return angle;
    },
    /*角度*/
    Angle(p1, p2, p3) {
      let bearing21 = this.Bearing(p2, p1);
      let bearing23 = this.Bearing(p2, p3);
      let angle = bearing21 - bearing23;
      if (angle < 0) {
        angle += 360;
      }
      return angle;
    },
    getArea(points) {
      let res = 0;
      //拆分三角曲面

      for (let i = 0; i < points.length - 2; i++) {
        let j = (i + 1) % points.length;
        let k = (i + 2) % points.length;
        let totalAngle = this.Angle(points[i], points[j], points[k]);

        let dis_temp1 = this.distance(points[j], points[0]);
        let dis_temp2 = this.distance(points[k], points[0]);
        res += (dis_temp1 * dis_temp2 * Math.sin(totalAngle)) / 2;
      }

      res = Math.abs(res).toFixed(4);
      // if (res < 1000000) {
      // } else {
      //   res = Math.abs((res / 1000000.0).toFixed(4)) + " 平方公里";
      // }

      return res;
    },
    distance(point1, point2) {
      let point1cartographic = Cesium.Cartographic.fromCartesian(point1);
      let point2cartographic = Cesium.Cartographic.fromCartesian(point2);
      /**根据经纬度计算出距离**/
      let geodesic = new Cesium.EllipsoidGeodesic();
      geodesic.setEndPoints(point1cartographic, point2cartographic);
      let s = geodesic.surfaceDistance;
      //console.log(Math.sqrt(Math.pow(distance, 2) + Math.pow(endheight, 2)));
      //返回两点之间的距离
      s = Math.sqrt(
        Math.pow(s, 2) +
        Math.pow(point2cartographic.height - point1cartographic.height, 2)
      );
      return s;
    },

    removeEntities() {
      for (let i = 0; i < GSAentities.length; i++) {
        viewer.entities.remove(GSAentities[i]);
      }
      GSAentities = [];
    },
  },
};
</script>
  
<style lang="scss" scoped>
.cpt-MMC_Gimbal {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 49px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 400px;
  box-sizing: border-box;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg,
            #e3aa77 0%,
            #f5cda9 38%,
            #f9ecd3 58%,
            #fcdbb1 79%,
            #edb07a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    height: 341px;
    overflow-y: auto;
    overflow-x: hidden;

    .color_num {
      div {
        font-size: 8px;
        line-height: 20px;
      }
    }

    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #ffffff;
          // width: 105px;
        }

        .input-box {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.MMC_GimbalList {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  top: calc(50% - 200px);
  height: 400px;
  margin-right: -200px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
}

::v-deep {
  .el-select {
    width: 265px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #02173d;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 265px;
    margin-right: 5px;

    .el-input__inner {
      background: #02173d;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .butto {
    width: 35px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    background: #004fff;
    color: #000;
    border-radius: 5px;
  }

  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}

/deep/.el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}

// 变焦功能
/deep/ .el-slider__runway {
  visibility: hidden;
}

.form_item {
  display: flex;
  // margin-bottom: 15px;
  // text-align: end;
  color: #cbd0eb;

  .form_item_title {
    width: 85px;
  }

  .mono_box {
    width: 28px;
    height: 13px;
    border-radius: 3px;
    margin-top: 2px;
    cursor: pointer;
  }

  .active {
    background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
    // background: url("~@/assets/images/accident/mono_box.svg") no-repeat !important;
    background-size: 100% 100%;
  }

  .mono-short,
  .mono-long,
  .slider,
  .text {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .mono-left {
    margin-left: 2px;
  }

  .mono-right {
    margin-right: 2px;
  }

  .mono-short {
    width: 111px;
    height: 17px;
    background: url("~@/assets/images/mount_short.png") no-repeat;
    background-size: 100%;
    margin-bottom: 5px;
  }

  .mono-long {
    width: 168px;
    height: 17px;
    background: url("~@/assets/images/mount_long.png") no-repeat;
    background-size: 100%;
    margin-bottom: 5px;
  }

  .imgs {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    &>img {
      cursor: pointer;
      width: 32px;
      height: 32px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .slider_box {
    width: 140px;
    margin: -7px 5px 0;
  }

  .symbol {
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #004fff;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    padding-left: 1px;

    &:hover {
      opacity: 0.7;
    }
  }
}

.img_bottom {
  display: flex;
  width: 320px;
  justify-content: space-between;

  &>img {
    cursor: pointer;
    width: 130px;
    height: 130px;
  }
}

::v-deep {
  .el-date-editor .el-range-input {
    background: #02173d;
  }

  .el-date-editor .el-range-separator {
    color: #ffff;
  }

  .el-select,
  .el-input {
    width: 200px;
    height: 24px;
  }

  .el-input__inner {
    width: 200px;
    height: 35px;
    background: rgba(13, 34, 79, 0.65);
    border: 1px solid #004fff;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }

  .el-date-editor {
    width: 300px;

    .el-input__inner {
      width: 300px;
    }
  }

  .el-select .el-input .el-select__caret {
    line-height: 22px;
    margin-top: 4px;
  }

  // 修改input清除按钮样式
  .el-input__suffix {
    .el-input__suffix-inner {
      .el-icon-circle-close:before {
        line-height: 16px;
        position: absolute;
        top: 5px;
        right: 3px;
      }
    }
  }

  .el-button {
    // padding: 0;
    background: #384dc5;
    border-radius: 2px;
    // width: 43px;
    // height: 24px;
    outline: none;
    color: #ffff;
    border: none;

    &:hover {
      opacity: 0.7;
    }
  }
}

.text-right {
  width: 30px;
}

.activeG {
  background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
  background-size: 100% 100%;
}

.ziticolor {
  color: #dce9ff;
  font-size: 14px;
}
</style>