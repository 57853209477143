import mqtt from "mqtt";
import dayjs from "dayjs";
const {
  VUE_APP_FCMQTT_URL
} = process.env;
export default {
  initGasDetection(GasDetectionData) {
    let {
      longitude,
      latitude
    } = GasDetectionData
    // 将经纬度转换为 Cartographic 坐标
    const positionCartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
    // 将 Cartographic 坐标转换为 Cartesian3 坐标
    const positionCartesian = Cesium.Cartographic.toCartesian(positionCartographic);
    let data = {
      ...GasDetectionData,
      position: positionCartesian
    }
    this.gasDetectionObj = data
    this.gasDetection.push(data)
  },
  uint8array2json(uint8array) {
    if (!uint8array || !uint8array.length) {
      return uint8array;
    }
    let {
      decoder
    } = this.mqtt;
    if (!decoder) {
      decoder = new TextDecoder("utf-8");
    }
    let content = decoder.decode(uint8array);
    return content ? JSON.parse(content) : content;
  },
  json2uint8array(data) {
    if (!data) {
      return data;
    }
    let {
      encoder
    } = this.mqtt;
    if (!encoder) {
      encoder = new TextEncoder();
    }
    let uint8array = encoder.encode(JSON.stringify(data));
    return uint8array;
  },
  subscribe(topic, callback) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      console.log(topic, 'topic------------');
      client.subscribe(topic, callback);
    }
  },
  unsubscribe(topic) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      client.unsubscribe(topic);
    }
  },
  initMqtt() {
    try {
      let {
        user_info,
      } = this;
      if (this.mqtt.client) {
        this.mqtt.client.end();
        this.mqtt.client = null;
      }
      let url = VUE_APP_FCMQTT_URL;
      let protocol = url.replace(/(.+)\:\/\/.*/, "$1");
      this.mqtt.client = mqtt.connect(url, {
        protocol,
        clientId: user_info.id,
      });

      this.mqtt.client.on("connect", () => {
        console.log('订阅成功');
      });
      this.mqtt.client.on("reconnect", function (reconnect) { });
      this.mqtt.client.on("`error`", (error) => { });
      this.mqtt.client.on("message", (topic, payload, packet) => {
        let data = this.uint8array2json(payload);
        let {
          latitude,
          longitude
        } = data;
        // 将经纬度转换为 Cartographic 坐标
        let positionCartographic = Cesium.Cartographic.fromDegrees(longitude, latitude);
        // 将 Cartographic 坐标转换为 Cartesian3 坐标
        let positionCartesian = Cesium.Cartographic.toCartesian(positionCartographic);
        this.positions = positionCartesian
        this.customProperty = data
        for (let key in data.airData) {
          let item = data.airData[key];
          this.$set(this.airData, key, item)
          // 
          this.calibrationGas(key, item)
        }
      });
      let GAStopic = this.GAStopic
      // 订阅数据
      this.subscribe(GAStopic)
    } catch (error) {
      console.log(error, 'errorerror');
    }
  },
  calibrationGas(key, item) {
    if (key == "CO(ppm)" || key == "NO2(ppm)") {
      if (item > 5) {
        this.warningInfor = {
          title: "危险提示",
          msg: '检测到气体' + key + '数值为' + item + ',大于5ppm已对人体造成伤害，请注意防护'
        }
        this.visible = true
      }
    } else if (key == "Ox(ppm)" && item > 0.15) {
      this.warningInfor = {
        title: "危险提示",
        msg: '检测到气体' + key + '数值为' + item + ',大于0.15ppm已对人体造成伤害，请注意防护'
      }

      this.visible = true
    } else if (key == "CO(ppm)" && item > 35) {
      this.warningInfor = {
        title: "危险提示",
        msg: '检测到气体' + key + '数值为' + item + ',大于35ppm已对人体造成伤害，请注意防护'
      }
      this.visible = true
    }
  },

}