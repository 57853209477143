<template>
  <div class="chart-container-box ml55 pr" @click="$emit('fn','Ox(ppm)')">
    <span class="pa top20 left-64 tc cp cf" style="z-index:999">
      <div>Ox</div>(ppm)
    </span>
    <div ref="Ox" class="chart w299 h65"></div>
    <div class="pa right8 cf tc top8">{{ value&&value.toFixed(2) }}</div>
  </div>
</template>
  
<script>
import echarts from "echarts";

export default {
  name: "Ox",
  data() {
    return {
      chart: null,
      value: 0,
      option: {
        color: ["#1fca21"],
        // title: {
        //     text: '动态数据',
        //     left: 'left', // 设置标题位置为左侧中间
        //     textAlign: 'center', // 设置标题在左侧居中显示
        //     // 其他选项...
        // },
        grid: {
          top: "40%",
          height: "40%", // 调整 grid 的 height 值，缩小图表的高度
          borderWidth: 1,
          borderColor: "#294598",
        },
        backgroundColor: "#02173dc4",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          show: false, // 隐藏 Y 轴
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          show: false, // 隐藏 Y 轴
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "Ox",
            type: "line",
            showSymbol: false,
            hoverAnimation: false,
            data: [],
          },
        ],
      },
      time:null
    };
  },
  props: {
    airData: {
      type: String | Number,
      default: () => 0,
    },
  },
  mounted() {
    this.initChart();
       this.time= setInterval(() => {
      if(this.airData>=0){
        this.updateData(this.airData);
      }
    }, 1000);
  },
    beforeDestroy(){
    clearInterval(this.time)
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.Ox);
      this.chart.setOption(this.option);
    },
    updateData(value) {
      const now = new Date();
      this.value = value;
      this.option.series[0].data.push([now, value]);
      this.chart.setOption(this.option);
    },
  },
};
</script>
  
<style scoped lang="scss">
.chart-container-box {
  width: 300px;
  border: 1px solid #1f4ef5;

  .chart {
    height: 73px;
  }
}
</style>