<template>
  <div class="chart-container-box ml55 pr" @click="$emit('fn','CO(ppm)')">
    <span class="pa top7 left-60 tc cf cp" style="z-index:999">
      <div>CO</div>(ppm)
    </span>
    <div ref="CO" class="chart w299 h65"></div>
    <div class="pa right8 cf tc top8">{{ value&&value.toFixed(2) }}</div>
  </div>
</template>
  
<script>
import echarts from "echarts";

export default {
  name: "CO",
  data() {
    return {
      chart: null,
      value: 0,

      option: {
        grid: {
          top: "40%",
          height: "40%", // 调整 grid 的 height 值，缩小图表的高度
          borderWidth: 1,
          borderColor: "#294598",
        },
        backgroundColor: "#02173dc4",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
          show: false, // 隐藏 Y 轴
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          show: false, // 隐藏 Y 轴
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "CO",
            type: "line",
            showSymbol: false,
            hoverAnimation: false,
            data: [],
          },
        ],
      },
    };
  },
  props: {
    airData: {
      type: String | Number,
      default: () => 0,
    },
  },
  watch: {
    airData: {
      handler(val) {
        if (val) {
          this.updateData(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.CO);
      this.chart.setOption(this.option);
    },
    updateData(value) {
      const now = new Date();
      this.value = value;
      this.option.series[0].data.push([now, value]);
      this.chart.setOption(this.option);
    },
  },
};
</script>
  
<style scoped lang="scss">
.chart-container-box {
  width: 300px;
  border: 1px solid #1f4ef5;
}
</style>