import { render, staticRenderFns } from "./SO2.vue?vue&type=template&id=1f33508e&scoped=true&"
import script from "./SO2.vue?vue&type=script&lang=js&"
export * from "./SO2.vue?vue&type=script&lang=js&"
import style0 from "./SO2.vue?vue&type=style&index=0&id=1f33508e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f33508e",
  null
  
)

export default component.exports