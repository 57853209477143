export default {
  closeDialog() {
    this.visible = false
    this.warningInfor = {
      title: "危险提示",
      msg: 'Ox(ppm),大于0.1ppm对人体造成伤害（短期接触限值）'
    }
  },
  // 计算颜色
  color_from_value(num) {
    if (num == 0) {
      num = 0.1
    }
    let value = num;
    if (
      this.taskSituation == "CO(ppm)" ||
      this.taskSituation == "SO2(ppm)" ||
      this.taskSituation == "Ox(ppm)"
    ) {
      value = num / 5;
    } else if (this.taskSituation == "NO2(ppm)") {
      value = num * 2;
    } else if (
      this.taskSituation == "PM1.0(ug/m3)" ||
      this.taskSituation == "PM2.5(ug/m3)" ||
      this.taskSituation == "PM10(ug/m3)"
    ) {
      value = num / 2;
    }

    function interpolateColor(color1, color2, ratio) {
      if (color1 && color2) {
        let r1 = parseInt(color1.substr(1, 2), 16);
        let g1 = parseInt(color1.substr(3, 2), 16);
        let b1 = parseInt(color1.substr(5, 2), 16);
        let r2 = parseInt(color2.substr(1, 2), 16);
        let g2 = parseInt(color2.substr(3, 2), 16);
        let b2 = parseInt(color2.substr(5, 2), 16);

        let r = Math.round(r1 + (r2 - r1) * ratio);
        let g = Math.round(g1 + (g2 - g1) * ratio);
        let b = Math.round(b1 + (b2 - b1) * ratio);

        let hex = "#" + (r < 16 ? "0" : "") + r.toString(16) + (g < 16 ? "0" : "") + g.toString(16) + (b < 16 ? "0" : "") + b.toString(16);
        return hexToRgb(hex)
      }
    }

    function hexToRgb(hex) {
      hex = hex.replace("#", "");
      let r = parseInt(hex.substr(0, 2), 16);
      let g = parseInt(hex.substr(2, 2), 16);
      let b = parseInt(hex.substr(4, 2), 16);
      return Cesium.Color.fromBytes(r, g, b);
    }

    function getHexColor(number) {
      if (number === 0) {
        return "#47a447";
      } else if (number === 100) {
        return "#fb4747";
      } else if (number >= 0 && number <= 100) {
        // 计算相邻颜色的索引
        let lowerIndex = Math.floor(number / 10);
        let upperIndex = Math.ceil(number / 10);
        // 根据索引获取相邻颜色
        let lowerColor = getColorByIndex(lowerIndex);
        let upperColor = getColorByIndex(upperIndex);
        // 计算颜色之间的渐变比例
        let ratio = (number - lowerIndex * 10) / 10;
        if (!upperColor) {
          upperColor = lowerColor
        }
        return interpolateColor(lowerColor, upperColor, ratio);
      } else {
        return "Invalid number. Please enter a number from 0 to 100.";
      }
    }

    function getColorByIndex(index) {
      let colors = [
        "#47a447", "#47c147", "#47de47", "#4bfb47", "#88fb47", "#c5fb47", "#fbf847", "#fbc847", "#fb9a47", "#fb4747"
      ];
      return colors[index];
    }

    // 示例用法
    let inputNumber = value
    let colorCode = getHexColor(inputNumber);
    // 返回 RGB 颜色值
    return colorCode
  },
  showLine(item) {
    this.add_polylineNeW(window.viewer, this.lineCopy);
    this.flyTo(window.viewer, this.positions);
  },
  Cartesian3_to_WGS84_Arr(point) {
    let cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z);
    let cartographic = Cesium.Cartographic.fromCartesian(cartesian33);
    let lat = Cesium.Math.toDegrees(cartographic.latitude);
    let lng = Cesium.Math.toDegrees(cartographic.longitude);
    let alt = cartographic.height;
    return [lng, lat, alt]
  },
  add_polylineNeW(viewer, data) {
    return viewer.entities.add({
      polyline: {
        positions: new Cesium.CallbackProperty(() => {
          return this.lineCopy;
        }, false),
        width: 5,
        material: Cesium.Color.fromCssColorString("#ff0000"),
        show: true,
        zIndex: 10,
      },
    });
  },
  addModel(viewer, position) {
    return viewer.entities.add({
      id: "nest_uav_model",
      position: new Cesium.CallbackProperty(() => this.positions, false),
      model: {
        uri: "cesium/model/mmcModel2.gltf",
        // color: Cesium.Color.fromAlpha(Cesium.Color.BLUE, parseFloat(0.8)), //模型颜色，透明度
        minimumPixelSize: 50, //指定模型的近似最小像素大小（不考虑缩放）的数值属性
        maximumScale: 10000, //模型的最大比例大小。最小像素化的上限
        silhouetteSize: "", //轮廓大小
        scale: 1,
        disableDepthTestDistance: 50000,
        //轮廓线
        silhouetteColor: Cesium.Color.fromAlpha(
          Cesium.Color.fromCssColorString("#4fafe3"),
          parseFloat(0.5)
        ),
        //模型样式 ['Highlight', 'Replace', 'Mix']
        colorBlendMode: Cesium.ColorBlendMode.Highlight,
        //colorBlendAmount需要选择mix后将colorBlendAmountEnabled设置为true才能使用
        colorBlendAmountEnabled: true,
        colorBlendAmount: parseFloat(0.8),
      },
    });
  },
  // dataFn() {
  //   let i = 0;
  //   this.lineCopy = [this.line[0]];
  //   setInterval(() => {
  //     if (i < this.line.length) {
  //       const item = this.line[i];
  //       const curPos = this.uavModel.position.getValue(
  //         Cesium.JulianDate.now()
  //       );
  //       const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z);
  //       const afterCart = new Cesium.Cartesian3(item.x, item.y, item.z);

  //       if (curCart.equals(afterCart)) {
  //         i++;
  //         return;
  //       }

  //       this.lineCopy.push(this.line[i]);
  //       this.positions = this.line[i];
  //       i++;
  //     }
  //   }, 50);
  // },
}